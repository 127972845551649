import React from "react";

import * as S from "./styles";
import illustration from "assets/images/service-software.png";

const Software: React.FC = () => {
  return (
    <S.Container>
      <S.Content className="left">
        <S.Title>Softwares Inteligentes</S.Title>
        <p>
          Para que todo projeto de segurança cumpra efetivamente com o seu
          papel, é necessário que o software utilizado disponibilize
          funcionalidades especiais para cada tipo de aplicação. Não se preocupe
          nossos especialistas vão te orientar em todo o processo!
        </p>

        <p>Os softwares podem ter diferentes funcionalidades:</p>
        <p>
          • Vigilância eletrônica;
          <br />
          • Monitoramento;
          <br />
          • Contagem de pessoas em vias públicas;
          <br />
          • Reconhecimento de placas;
          <br />
          • Acompanhamento de fluxo de veículos;
          <br />
          • Mapa de calor;
          <br />
          • Reconhecimento facial;
          <br />
          • identificação de armas de fogo;
          <br />
          • Identificação de máscaras (EPI);
          <br />• Cercas virtuais para proteção de perímetro.
        </p>

        <p>Solicite uma avaliação personalizada para sua empresa!</p>
      </S.Content>
      <S.Content className="right">
        <img src={illustration} alt="Monitoramento e Vigilância" />
      </S.Content>
    </S.Container>
  );
};

export default Software;
