import React from "react";
import Slider from "react-slick";

import * as S from "./styles";
import { BannerTermologia, BannerDrone } from "./Sliders";

const NextArrow = (props: any) => {
  const { onClick } = props;
  return (
    <S.NextButton onClick={onClick} whileHover={{ scale: 1.1 }}>
      <S.ChevronIconRight />
    </S.NextButton>
  );
};

const PrevArrow = (props: any) => {
  const { onClick } = props;
  return (
    <S.PrevButton onClick={onClick} whileHover={{ scale: 1.1 }}>
      <S.ChevronIconLeft />
    </S.PrevButton>
  );
};

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 7000,
  adaptiveHeight: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

const Banner: React.FC = () => {
  return (
    <S.Container>
      <Slider {...settings}>
        <BannerTermologia />
        <BannerDrone />
      </Slider>
    </S.Container>
  );
};

export default Banner;
