import styled from "styled-components";
import bg from "assets/images/slide2-bg.png";

export const Container = styled.section`
  width: 100%;
  max-width: 1370px;
  height: 630px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff url(${bg}) no-repeat center center;
  position: relative;
  padding: 32px;
`;

export const Title = styled.h1`
  font-size: 76px;
  font-family: "Gilroy-Light";
  color: #fff;
  margin-bottom: 24px;
  text-align: center;

  @media screen and (max-width: 600px) {
    font-size: 56px;
    line-height: 56px;
    padding-bottom: 160px;
  }
`;

export const Text = styled.p`
  display: block;
  width: 300px;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  position: absolute;
  bottom: 96px;
  right: 15%;

  @media screen and (max-width: 600px) {
    right: auto;
    text-align: center;
  }
`;
