import React from "react";
import { Element } from "react-scroll";

import * as S from "./styles";
import { Header } from "components/shared";
import {
  Banner,
  About,
  Security,
  Camera,
  Termo,
  Clients,
  Footer,
} from "components/Main";

const Main: React.FC = () => {
  return (
    <S.Container>
      <Header />
      <Banner />
      <Element name="About" />
      <About />
      <Element name="Security" />
      <Security />
      <Camera />
      <Termo />
      <Element name="Client" />
      <Clients />
      <Footer />
    </S.Container>
  );
};

export default Main;
