import styled from "styled-components";
import { motion } from "framer-motion";
import { ChevronLeft, ChevronRight } from "@styled-icons/fa-solid/";

export const Container = styled.section`
  width: 100%;
  max-width: 1370px;
  height: 630px;
  position: relative;
  z-index: 1;
  box-shadow: 20px 20px 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;
`;

export const ChevronButton = styled(motion.button).attrs({ type: "button" })`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  position: absolute;
  z-index: 2;
  top: 50%;
  color: #fff;
`;

export const PrevButton = styled(ChevronButton)`
  left: 32px;
`;

export const NextButton = styled(ChevronButton)`
  right: 32px;
`;

export const ChevronIconLeft = styled(ChevronLeft).attrs({ size: 32 })``;

export const ChevronIconRight = styled(ChevronRight).attrs({ size: 32 })``;
