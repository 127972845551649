import React from "react";

import * as S from "./styles";

const About: React.FC = () => {
  return (
    <S.Container>
      <S.Content>
        <S.PlusLeft />
        <S.SymbolCorp />
        <S.Title>A Guiro Corp</S.Title>
        <p>
          Somos uma empresa dedicada a tecnologia diversificada, atendemos a
          diversos verticais com compromisso de ajudar nossos clientes a
          possuírem as melhores tecnologias que existem no mercado global de
          soluções para segurança eletrônica.
        </p>
      </S.Content>
      <S.Content>
        <S.PlusRight />
        <S.Wrapper>
          <S.Subtitle>Foco no Cliente</S.Subtitle>
          <p>
            Vencemos quando nossos clientes vencem. Nossos relacionamentos
            estratégicos de longo prazo fornecem ideias únicas e a capacidade de
            provir ao cliente experiências e soluções excepcionais.
          </p>
        </S.Wrapper>
        <S.Wrapper>
          <S.Subtitle>Equipe</S.Subtitle>
          <p>
            Somos uma equipe dedicada a trabalhar de maneira colaborativa e em
            conjunto para criar soluções significativas que impulsionam o mundo
            para seguir adiante.
          </p>
        </S.Wrapper>
      </S.Content>
    </S.Container>
  );
};

export default About;
