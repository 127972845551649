import React from "react";

import * as S from "./styles";

const Access: React.FC = () => {
  return (
    <>
      <S.Title>Controle de Acesso</S.Title>
      <S.Container>
        <S.Content>
          <p>
            Cada vez mais se torna importante investir na segurança da sua
            empresa com equipamentos de segurança e ter um controle de acesso de
            qualidade faz toda a diferença. Através de um monitoramento
            rigoroso, controlando entrada/saída de pessoas e objetos e que você
            garantirá a integridade do patrimônio e a segurança dos
            colaboradores.
          </p>
          <p>
            Trabalhamos com o que há de mais moderno, garantindo assim total
            segurança da sua instituição.
          </p>
          <p>
            Todos os nossos equipamentos de controle de acesso, sejam eles
            eletrônicos ou digitais, funcionam com base em um mesmo princípio:
            autorização/permissão. Ou seja, um usuário é cadastrado no
            equipamento, e um nível de permissão lhe é concedido. Dessa forma,
            sempre que o acesso for solicitado, a solução autenticará através de
            biometria (facial ou por impressão digital), senha pessoal ou
            cartão.
          </p>
          <p>
            Trabalhamos com condições especiais (locação e venda) a instalação e
            manutenção já fazem parte do nosso projeto. Atuamos em todo o Brasil
            e a Guiro Corp possui clientes nos mais variados segmentos.
          </p>
        </S.Content>
        <S.Content>
          <p>
            <strong>
              Veja algumas das funcionalidades do nosso controle de acesso:
            </strong>
          </p>
          <p>
            • Emissão de relatório gerenciais com identificação e horário de
            entrada e saída clientes e visitantes;
            <br />
            • Emissão de Relatório de ponto de colaboradores;
            <br />
            • Restringir locais ou áreas específicas da sua empresa;
            <br />
            • Liberar e restringir abertura de portas, portões e cancelas de
            estacionamento;
            <br />• Configuração de acesso apenas para pessoas autorizadas em
            áreas restritas.
          </p>
          <p>
            <strong>Formas de Controle:</strong>
          </p>
          <p>
            • Catracas;
            <br /> • Cancelas;
            <br /> • Alarmes;
            <br /> • Leitor Biométrico.
          </p>
        </S.Content>
      </S.Container>
    </>
  );
};

export default Access;
