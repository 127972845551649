import React, { useRef, useState } from "react";
import { Element } from "react-scroll";
import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import axios from "axios";

import * as S from "./styles";
import { Button } from "styles/components";
import { notify } from "services";

import { useValidation } from "hooks";
import { Input, MaskedInput, Textarea } from "components/shared/Forms";
import villa from "assets/images/client-villa.png";
import bb from "assets/images/client-bb.png";
import calper from "assets/images/client-calper.png";
import petro from "assets/images/client-petro.png";

const baseURL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3333"
    : "https://guiro-corp-api.herokuapp.com/";
const api = axios.create({ baseURL });

interface IContactApiState {
  loading: boolean;
  error: null | string;
}
const InitialApiState: IContactApiState = {
  loading: false,
  error: null,
};

const Clients: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors } = useValidation();
  const [contactApiState, setContactApiState] = useState<IContactApiState>(
    InitialApiState
  );

  const handleApiCall = async (data: Record<string, any>) => {
    try {
      setContactApiState((state) => ({ ...state, loading: true }));
      await api.post("/contact", data);
      notify("success", "Mensagem enviada com sucesso");
    } catch (error) {
      const { message = "Não foi possível enviar seu e-mail" } = error;
      notify("error", "Não foi possível enviar sua mensagem");
      setContactApiState((state) => ({ ...state, error: message }));
    } finally {
      setContactApiState((state) => ({ ...state, loading: false }));
    }
  };

  const handleSubmit: SubmitHandler = async (data) => {
    try {
      if (formRef.current !== null) {
        formRef.current.setErrors({});
      }

      const schema = Yup.object().shape({
        nome: Yup.string().required("Informe seu nome completo"),
        email: Yup.string()
          .email("E-mail inválido")
          .required("Informe seu e-mail"),
        telefone: Yup.string().required("Informe seu telefone"),
        mensagem: Yup.string().required("Escreva sua mensagem"),
      });

      await schema.validate(data, { abortEarly: false });

      await handleApiCall(data);
    } catch (error) {
      handleFormErrors(error, formRef);
    }
  };

  return (
    <S.Container>
      <S.PlusLeft />
      <S.PlusRight />
      <S.Title>Nossos Clientes</S.Title>
      <S.Text>
        Veja algumas das empresas que confiam em nossas soluções de segurança e
        controle.
      </S.Text>
      <S.Wrapper>
        <img src={bb} alt="bb" />
        <img src={petro} alt="petro" />
        <img src={villa} alt="villa" />
        <img src={calper} alt="calper" />
      </S.Wrapper>
      <Element name="Contact" />
      <S.BlueBox>
        <S.SymbolCorp />
        <S.TitleBox>Fale com um Representante</S.TitleBox>
        <p>
          Fale com a Guiro Corp e saiba mais sobre as soluções que podemos
          oferecer para o seu negócio.
        </p>
        <S.FormContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <S.FormRow>
              <Input name="nome" label="Nome:" required />
            </S.FormRow>
            <S.FormRow>
              <Input name="email" label="E-mail:" required />
              <MaskedInput type="telefone" name="telefone" label="Telefone:" />
            </S.FormRow>
            <S.FormRow>
              <Textarea name="mensagem" label="Mensagem:" required />
            </S.FormRow>
            <Button
              disabled={contactApiState.loading}
              btStyle="primary"
              type="submit"
              whileHover={{ scale: 1.1 }}
            >
              {contactApiState.loading
                ? "Enviando mensagem..."
                : "Enviar mensagem"}
            </Button>
          </Form>
        </S.FormContainer>
      </S.BlueBox>
    </S.Container>
  );
};

export default Clients;
