import styled from "styled-components";
import cam from "assets/images/cam.png";

export const Container = styled.section`
  display: flex;
  width: 100%;
  max-width: 1920px;
  height: 710px;
  background-color: #000103;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    height: 1000px;
  }

  @media screen and (max-width: 500px) {
    flex-direction: column;
    height: auto;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1000px) {
    max-width: 100%;
  }

  p {
    font-size: 16px;
    line-height: 22px;
    color: #fff;

    @media screen and (max-width: 500px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &.left {
    align-items: flex-end;
    background: #000103 url(${cam}) no-repeat;
    background-position-x: left;
    background-position-y: bottom;
    padding-left: 32px;

    @media screen and (max-width: 1430px) {
      background-position-x: center;
    }
    @media screen and (max-width: 1000px) {
      background-size: contain;
    }
    @media screen and (max-width: 500px) {
      height: 340px;
    }
    @media screen and (max-width: 375px) {
      background-size: cover;
    }
  }

  &.right {
    align-items: flex-start;
    justify-content: center;
    padding: 48px;

    @media screen and (max-width: 1000px) {
      align-items: center;
    }
    @media screen and (max-width: 500px) {
      padding: 64px 32px;
    }
  }
`;

export const Title = styled.h1`
  font-size: 46px;
  line-height: 46px;
  color: #fff;
  margin-bottom: 16px;

  @media screen and (max-width: 500px) {
    font-size: 28px;
    line-height: 28px;
    text-align: center;
  }
`;

export const Subtitle = styled.h1`
  font-size: 26px;
  font-family: "Gilroy-Regular";
  line-height: 26px;
  color: #1da6c7;
  margin-bottom: 40px;

  @media screen and (max-width: 500px) {
    font-size: 18px;
    line-height: 18px;
    text-align: center;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 510px;
`;
