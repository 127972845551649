import React from "react";

import * as S from "./styles";

const Termo: React.FC = () => {
  return (
    <S.Container>
      <S.Content className="left">
        <S.Wrapper>
          <S.Title>Aferição digital de temperatura</S.Title>
          <S.Subtitle>
            Checagem de temperatura corporal por meio de imagens de câmera
          </S.Subtitle>
          <p>
            Infelizmente o mundo passa por uma pandemia, e um dos principais
            sintomas da Covid-19 (e de outras doenças) é a febre. A instalação
            de câmeras termográficas auxiliam na triagem preliminar de possíveis
            infectados, sem contato físico e com uma velocidade de leitura de
            alguns segundos, aumentando a eficiência e a segurança.
          </p>
        </S.Wrapper>
      </S.Content>
      <S.Content className="right">
        <S.WrapperPhone>
          <S.CircleBig
            animate={{ scale: [0.5, 1] }}
            transition={{
              ease: "easeInOut",
              duration: 1.5,
              repeat: Infinity,
              repeatType: "reverse",
            }}
          >
            <S.Circle
              animate={{ scale: [1, 1.2] }}
              transition={{
                ease: "easeInOut",
                duration: 1,
                repeat: Infinity,
                repeatType: "reverse",
              }}
            />
          </S.CircleBig>
          <S.Iphone />
        </S.WrapperPhone>
      </S.Content>
    </S.Container>
  );
};

export default Termo;
