import styled from "styled-components";

export const Container = styled.main`
  width: 100%;
  max-width: 1920px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  position: relative;

  ::before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 580px;
    background-color: #eaeaea;
    z-index: 0;
  }
`;
