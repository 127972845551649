import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  display: flex;

  @media screen and (max-width: 1066px) {
    flex-direction: column;
    padding: 0 32px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1066px) {
    &:last-child {
      margin-top: 32px;
    }
  }

  &.left {
    width: 100%;
    max-width: 40%;

    @media screen and (max-width: 1066px) {
      max-width: 100%;
    }
  }

  &.right {
    width: 100%;
    max-width: 60%;
    align-items: flex-end;

    @media screen and (max-width: 1066px) {
      max-width: 100%;
      align-items: center;
    }

    img {
      width: 100%;
      max-width: max-content;
    }
  }
  p {
    font-size: 16;
    line-height: 22px;
    color: #333;
    margin-bottom: 24px;
  }
`;

export const Title = styled.h1`
  font-family: "Gilroy-SemiBold";
  font-size: 62px;
  line-height: 62px;
  color: #000;
  margin-bottom: 32px;

  @media screen and (max-width: 500px) {
    font-size: 32px;
    line-height: 32px;
  }
`;
