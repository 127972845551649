import styled, { css } from "styled-components";
import { motion } from "framer-motion";

type ButtonStyleOption =
  | "primary"
  | "secundary"
  | "transparent"
  | "cancel"
  | "danger";

interface ButtonStyle {
  btStyle?: ButtonStyleOption;
}

const styleButton = {
  primary: css`
    background-color: #0075b0;
    color: #fff;
  `,
  secundary: css`
    background-color: #1da6c7;
    color: #fff;
  `,
  transparent: css`
    background-color: transparent;
    color: #0075b0;
    padding: 16px 0;
  `,
  cancel: css`
    background-color: #e0e0e0;
    color: #888;
  `,
  danger: css`
    background-color: #ff2e4a;
    color: #fff;
  `,
};

export const Button = styled(motion.button)<ButtonStyle>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 16px 24px;
  border-radius: 10px;

  ${({ btStyle }) => (btStyle ? styleButton[btStyle] : styleButton.primary)}
`;
