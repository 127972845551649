import React from "react";

import * as S from "./styles";

const Facial: React.FC = () => {
  return (
    <>
      <S.Title>Reconhecimento Facial</S.Title>
      <S.Container>
        <S.Content>
          <p>
            O reconhecimento facial deixou de ser um assunto de filme de ficção
            científica, e passou a ser um serviço de segurança para a vida real.
            Mas, como funciona esse serviço? Trata-se de uma técnica de
            biometria baseada nos pontos do rosto das pessoas. Para isso
            acontecer, precisa-se definir traços únicos que devem ser mapeados
            em códigos binários.
          </p>

          <p>
            Através de um banco de dados, nossa tecnologia reconhece e captura
            informações do rosto que acabou de ter suas imagens em destaque.
          </p>

          <p>A Guiro Corp oferece:</p>

          <p>
            • Monitoramento centralizado de múltiplas localidades em tempo real;
            <br />
            • Prevenção de atividades que potencialmente ameaçam a segurança;
            <br />
            • Criação de regras dentro do sistema que melhor se adaptem à
            segurança e ao negócio do cliente;
            <br />• Emissão de relatórios diversos com data e horário em que o
            indivíduo esteve no local.
          </p>
        </S.Content>
        <S.Content>
          <p>
            Mas para que serve essa solução? A mesma pode substituir chaves,
            biometria e etc., permitindo a entrada de pessoas autorizadas como:
            clubes, academias, condomínio e espaços restritos de empresas. Em
            alguns países a solução também é muito utilizada pela
            polícia/governo, onde é possível detectar suspeitos ou criminosos,
            até mesmo em multidões. Existindo um banco de dados é possível ter
            acesso a informações como: CPF, RG, endereço residencial e
            corporativo.
          </p>

          <p>Faça agora um orçamento e surpreenda com nossa solução!</p>
        </S.Content>
      </S.Container>
    </>
  );
};

export default Facial;
