import React from "react";

import * as S from "./styles";

const Footer: React.FC = () => {
  return (
    <S.Container>
      <S.Content>
        <S.Logo />
        <S.About>
          Somos uma empresa dedicada a tecnologia diversificada, atendemos a
          diversos verticais com compromisso de ajudar nossos clientes a
          possuírem as melhores tecnologias que existem no mercado global de
          soluções para segurança eletrônica.
        </S.About>
        <S.Wrapper>
          <S.Title>Contato</S.Title>
          <p>
            Rua Des. Renato Tavares, 11 Rio de Janeiro/RJ | (21) 98386-0006 |
            rodrigo@guirocorp.com
          </p>
        </S.Wrapper>
        <S.Wrapper className="social">
          <S.Title>Siga-nos</S.Title>
          <S.Social>
            {/* <S.IconFacebook /> */}
            <a href="https://www.instagram.com/guirocorp/">
              <S.IconInstagram />
            </a>
            <a href="https://www.linkedin.com/company/guirocorp/">
              <S.IconLinkedin />
            </a>
          </S.Social>
        </S.Wrapper>
      </S.Content>
      <S.Dev>
        <span>Guiro Corp 2020. Todos os direitos reservados.</span>
        <span>
          Desenvolvido por{" "}
          <a href="https://fococomunicacao.com">Foco Comunicação.</a>
        </span>
      </S.Dev>
    </S.Container>
  );
};

export default Footer;
