import { createGlobalStyle } from "styled-components";

import GilroyBold from "assets/fonts/Gilroy-Bold.otf";
import GilroyExtraBold from "assets/fonts/Gilroy-ExtraBold.otf";
import GilroyThin from "assets/fonts/Gilroy-Thin.otf";
import GilroyLight from "assets/fonts/Gilroy-Light.otf";
import GilroyRegular from "assets/fonts/Gilroy-Regular.otf";
import GilroySemiBold from "assets/fonts/Gilroy-SemiBold.otf";

const GlobalStyle = createGlobalStyle`

@font-face {
    font-family: "Gilroy-Bold";
    src: url(${GilroyBold});
  }

  @font-face {
    font-family: "Gilroy-ExtraBold";
    src: url(${GilroyExtraBold});
  }

  @font-face {
    font-family: "Gilroy-Light";
    src: url(${GilroyLight});
  }
  
  @font-face {
    font-family: "Gilroy-Thin";
    src: url(${GilroyThin});
  }

  @font-face {
    font-family: "Gilroy-Regular";
    src: url(${GilroyRegular});
  }

  @font-face {
    font-family: "Gilroy-SemiBold";
    src: url(${GilroySemiBold});
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
  }

  html,
  body,
  #root {
    height: 100%;
    scroll-behavior: smooth;
  }

  body {
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    background:#fff;
    font-family: "Rubik", sans-serif;
    font-size: 16px;
    color: #333;
    font-weight: normal;
    overflow-x: hidden;
    text-align: justify !important;
    text-justify: auto !important;
  }

  a {
    text-decoration: none;
  }

  button {
    font-family: "Gilroy-SemiBold";
    border: 0;
    cursor: pointer;
    background-color: transparent;
  }

  input, textarea {
    font-family: "Gilroy-Regular";
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: "Gilroy-Bold";
  }

  ul {
    list-style: none;
  }

  .Toastify__toast--error {
    border-radius: 6px;
    background-color: #e74c3c;
  }
  .Toastify__toast-body {
    font-family: "Gilroy-SemiBold";
    font-size: 14px;
    padding: 0 16px;
  }
  .toast-success {
    border-radius: 6px;
    background-color: #0075b0;
  }

  .icon-spin {
    animation: iconSpin 2s infinite linear;
  }

  @keyframes iconSpin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
`;

export default GlobalStyle;
