import React from "react";

import * as S from "./styles";

const Drone: React.FC = () => {
  return (
    <S.Container>
      <S.Title>Segurança vinda do futuro.</S.Title>
      <S.Text>
        Tecnologia e soluções de alto desempenho para segurança eletrônica e
        controle de pessoas e ambientes.
      </S.Text>
    </S.Container>
  );
};

export default Drone;
