import React from "react";

import * as S from "./styles";
import illustration from "assets/images/service-security.png";

const SecurityPatri: React.FC = () => {
  return (
    <S.Container>
      <S.Content className="left">
        <S.Title>Segurança Perimetral</S.Title>
        <p>
          Investir na segurança perimetral oferece muitos benefícios. Mas, você
          sabe o que significa? Trata-se de barreiras (psicológicas,
          tecnológicas e físicas) cujo objetivo é impedir a invasão de pessoas
          não autorizadas, onde ocorre a inibição e detecção de situação de
          risco.
        </p>

        <p>Veja abaixo os três tipos de segurança perimetral:</p>

        <p>
          1º tipo Barreiras Físicas: cercas elétricas, concertinas e arame
          farpados são alguns tipos de barreiras físicas;
        </p>

        <p>
          2º tipo Barreiras Psicológicas: o objetivo dessas ações aqui é
          impor/inibir a entrada de pessoas. Exemplos: placas de avisos de cão
          feroz e de câmeras e outras ações;
        </p>

        <p>
          3º tipo Barreiras Tecnológicas: alguns exemplos: sensores externos
          instalados sob os muros, são equipamentos bastante utilizados. Em suas
          funções, destaca-se um transmissor e outro receptor, onde é emitido
          feixes de raio-infravermelho que, ao serem interrompidos com a
          passagem de uma pessoa, envia um alarme para a central de
          monitoramento.
        </p>
      </S.Content>
      <S.Content className="right">
        <img src={illustration} alt="Monitoramento e Vigilância" />
      </S.Content>
    </S.Container>
  );
};

export default SecurityPatri;
