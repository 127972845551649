import React, { useState, useCallback } from "react";

import {
  Monitor,
  SecurityPatri,
  Access,
  Termografia,
  Facial,
  Software,
} from "./Services";
import * as S from "./styles";

import { ReactComponent as IconAccess } from "assets/images/icon-access-control.svg";
import { ReactComponent as IconFacial } from "assets/images/icon-facial-recognition.svg";
import { ReactComponent as IconMonitor } from "assets/images/icon-monitor.svg";
import { ReactComponent as IconSecurity } from "assets/images/icon-security.svg";
import { ReactComponent as IconSoftware } from "assets/images/icon-software.svg";
import { ReactComponent as IconTermo } from "assets/images/icon-termo.svg";

type serviceType =
  | "Monitoramento"
  | "Segurança"
  | "Acesso"
  | "Termografia"
  | "Facial"
  | "Software";

interface ServiceProps {
  onSelect: (value: serviceType) => void;
}

const IconLeft: React.FC = () => (
  <S.BoxIconLeft>
    <S.IconLeft
      animate={{ y: [0, -10] }}
      transition={{
        ease: "easeInOut",
        duration: 2,
        repeat: Infinity,
        repeatType: "reverse",
      }}
    />
    <S.LeftSquare
      animate={{ scale: [1, 0.8], opacity: [1, 0.2] }}
      transition={{
        ease: "easeInOut",
        duration: 2,
        repeat: Infinity,
        repeatType: "reverse",
      }}
    />
    <S.LeftSquareBig
      animate={{ scale: [1, 0.8], opacity: [1, 0] }}
      transition={{
        ease: "easeInOut",
        duration: 2,
        repeat: Infinity,
        repeatType: "reverse",
      }}
    />
  </S.BoxIconLeft>
);

const IconRight: React.FC = () => (
  <S.BoxIconRight>
    <S.IconRight
      animate={{ y: [0, 10] }}
      transition={{
        ease: "easeInOut",
        duration: 2,
        repeat: Infinity,
        repeatType: "reverse",
      }}
    />
    <S.RightSquare
      animate={{ scale: [1, 0.8], opacity: [1, 0.2] }}
      transition={{
        ease: "easeInOut",
        duration: 2,
        repeat: Infinity,
        repeatType: "reverse",
      }}
    />
    <S.RightSquareBig
      animate={{ scale: [1, 0.8], opacity: [1, 0] }}
      transition={{
        ease: "easeInOut",
        duration: 2,
        repeat: Infinity,
        repeatType: "reverse",
      }}
    />
  </S.BoxIconRight>
);

const Services: React.FC<ServiceProps> = ({ onSelect }) => (
  <S.BoxServices>
    <S.ServiceButton
      whileHover={{ color: "#1DA6C7" }}
      onClick={() => onSelect("Monitoramento")}
    >
      <IconMonitor />
      Monitoramento
    </S.ServiceButton>
    <S.ServiceButton
      whileHover={{ color: "#1DA6C7" }}
      onClick={() => onSelect("Segurança")}
    >
      <IconSecurity />
      Segurança Perimetral
    </S.ServiceButton>
    <S.ServiceButton
      whileHover={{ color: "#1DA6C7" }}
      onClick={() => onSelect("Acesso")}
    >
      <IconAccess />
      Controle de Acesso
    </S.ServiceButton>
    <S.ServiceButton
      whileHover={{ color: "#1DA6C7" }}
      onClick={() => onSelect("Termografia")}
    >
      <IconTermo />
      Termografia
    </S.ServiceButton>
    <S.ServiceButton
      whileHover={{ color: "#1DA6C7" }}
      onClick={() => onSelect("Facial")}
    >
      <IconFacial />
      Reconhecimento Facial
    </S.ServiceButton>
    <S.ServiceButton
      whileHover={{ color: "#1DA6C7" }}
      onClick={() => onSelect("Software")}
    >
      <IconSoftware />
      Softwares Inteligentes
    </S.ServiceButton>
  </S.BoxServices>
);

const Footer = () => (
  <S.BoxFooter>
    <S.ItemFooter>
      <h5>Projeto</h5>
      <p>
        Nossos profissionais qualificados avaliam as necessidades da sua empresa
        e desenham projetos personalizados com soluções de ótima qualidade e
        softwares tecnológicos.
      </p>
    </S.ItemFooter>
    <S.ItemFooter>
      <h5>Instalação</h5>
      <p>
        A instalação é realizada por nossos técnicos e acompanhado por um
        gerente de projeto, que coordenará todo o processo de instalação.
      </p>
    </S.ItemFooter>
    <S.ItemFooter>
      <h5>Manutenção</h5>
      <p>
        Em caso de necessidade, o suporte técnico estará à disposição para
        atender qualquer eventualidade.
      </p>
    </S.ItemFooter>
  </S.BoxFooter>
);

const Security: React.FC = () => {
  const [service, setService] = useState<serviceType>("Monitoramento");

  const showService = useCallback((show: serviceType) => {
    if (show === "Monitoramento") {
      return <Monitor />;
    }

    if (show === "Segurança") {
      return <SecurityPatri />;
    }

    if (show === "Acesso") {
      return <Access />;
    }

    if (show === "Termografia") {
      return <Termografia />;
    }

    if (show === "Facial") {
      return <Facial />;
    }

    if (show === "Software") {
      return <Software />;
    }

    return <></>;
  }, []);

  return (
    <S.Container>
      <S.Header>
        <S.Plus />
        <IconLeft />
        <IconRight />
        <S.Title>Segurança</S.Title>
        <S.Subtitle>Eletrônica</S.Subtitle>
        <S.Disclaimer>Produtos e Implantação de Projetos</S.Disclaimer>
        <S.Text>
          Temos um portfólio de produtos e soluções corporativas de segurança
          eletrônica. Com equipamentos de última geração, como câmeras
          inteligentes, reconhecimento facial, câmeras com termografia,
          identificação de uso de máscaras, zonas de mapeamento de calor,
          identificação de áreas com aglomerações e leitura de placas.
        </S.Text>
        <Services onSelect={setService} />
      </S.Header>
      <S.Content>{showService(service)}</S.Content>
      <Footer />
    </S.Container>
  );
};

export default Security;
