import styled from "styled-components";
import { motion } from "framer-motion";
import plus from "assets/images/security-plus.png";
import iconRight from "assets/images/icon-security.png";
import iconLeft from "assets/images/icon-mask.png";

export const Container = styled.section`
  width: 100%;
  max-width: 1920px;
  padding-bottom: 96px;
  overflow: hidden;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 710px;
  padding: 54px 32px;
  position: relative;
  background: rgb(13, 45, 100);
  background: linear-gradient(
    45deg,
    rgba(13, 45, 100, 1) 20%,
    rgba(4, 15, 65, 1) 100%
  );
`;

export const Title = styled.h2`
  font-size: 76px;
  line-height: 76px;
  color: #fff;
  margin-bottom: 8px;
  text-align: center;

  @media screen and (max-width: 500px) {
    font-size: 56px;
    line-height: 56px;
  }

  @media screen and (max-width: 375px) {
    font-size: 48px;
    line-height: 48px;
  }
`;

export const Subtitle = styled(Title)`
  font-family: "Gilroy-Thin";
  color: #1fb8dd;
`;

export const Disclaimer = styled.span`
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 40px;

  @media screen and (max-width: 500px) {
    text-align: center;
  }

  @media screen and (max-width: 375px) {
    font-size: 16px;
    line-height: 16px;
  }
`;

export const Text = styled.p`
  display: block;
  width: 100%;
  max-width: 580px;
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  color: #8aa9e8;

  @media screen and (max-width: 375px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const Plus = styled.img.attrs({ src: plus })`
  position: absolute;
  top: 100px;
  left: 20%;
`;

export const BoxIconRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
  position: absolute;
  top: 124px;
  right: 10%;

  @media screen and (max-width: 1230px) {
    right: -5%;
  }
  @media screen and (max-width: 890px) {
    display: none;
  }
`;

export const RightSquareBig = styled(motion.div)`
  position: absolute;
  border-radius: 30px;
  width: 200px;
  height: 200px;
  background-color: rgba(1, 119, 177, 0.15);
`;

export const RightSquare = styled(motion.div)`
  position: absolute;
  border-radius: 30px;
  width: 156px;
  height: 156px;
  background-color: rgba(1, 119, 177, 0.15);
`;

export const IconRight = styled(motion.img).attrs({ src: iconRight })`
  position: absolute;
`;

export const BoxIconLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
  position: absolute;
  bottom: 182px;
  left: 10%;

  @media screen and (max-width: 1230px) {
    left: -5%;
  }
  @media screen and (max-width: 890px) {
    display: none;
  }
`;

export const LeftSquareBig = styled(motion.div)`
  position: absolute;
  border-radius: 30px;
  width: 200px;
  height: 200px;
  background-color: rgba(1, 163, 177, 0.15);
`;

export const LeftSquare = styled(motion.div)`
  position: absolute;
  border-radius: 30px;
  width: 156px;
  height: 156px;
  background-color: rgba(1, 163, 177, 0.15);
`;

export const IconLeft = styled(motion.img).attrs({ src: iconLeft })`
  position: absolute;
`;

export const BoxServices = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 830px;
  border-radius: 8px;
  border: 1px #cecece solid;
  background-color: #fff;
  position: absolute;
  bottom: -89px;

  @media screen and (max-width: 620px) {
    flex-direction: column;
    max-width: 300px;
    bottom: -200px;
  }
`;

export const ServiceButton = styled(motion.button).attrs({ type: "button" })`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-family: "Rubik";
  font-size: 16px;
  width: 33.33%;
  height: 88px;

  @media screen and (max-width: 620px) {
    width: 100%;
    height: auto;
    justify-content: flex-start;
    padding: 16px 24px;

    &:nth-child(5) {
      border-bottom: 1px #cecece solid;
    }
  }

  svg {
    margin-right: 12px;
  }

  &:first-child {
    border-right: 1px #cecece solid;
    border-bottom: 1px #cecece solid;

    @media screen and (max-width: 620px) {
      border-right: 0;
    }
  }

  &:nth-child(2) {
    border-bottom: 1px #cecece solid;
  }

  &:nth-child(3) {
    border-left: 1px #cecece solid;
    border-bottom: 1px #cecece solid;

    @media screen and (max-width: 620px) {
      border-left: 0;
    }
  }

  &:nth-child(4) {
    border-right: 1px #cecece solid;

    @media screen and (max-width: 620px) {
      border-right: 0;
      border-bottom: 1px #cecece solid;
    }
  }

  &:last-child {
    border-left: 1px #cecece solid;

    @media screen and (max-width: 620px) {
      border-left: 0;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1170px;
  padding-top: 200px;
  padding-bottom: 64px;
  margin: 0 auto;

  @media screen and (max-width: 620px) {
    padding-top: 260px;
  }
`;

export const BoxFooter = styled.div`
  display: flex;
  width: 100%;
  max-width: 1170px;
  border-radius: 8px;
  border: 1px #ddd solid;
  background-color: #f0f0f0;
  margin: 0 auto;

  @media screen and (max-width: 680px) {
    flex-direction: column;
  }
`;

export const ItemFooter = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  width: 33.33%;
  padding: 48px;

  @media screen and (max-width: 680px) {
    width: 100%;
  }

  h5 {
    font-family: "Gilroy-SemiBold";
    font-size: 24px;
    line-height: 24px;
    color: #00446f;
    margin-bottom: 16px;
  }

  p {
    font-size: 14px;
    line-height: 18px;
  }
`;
