import { toast } from "react-toastify";

export function notify(
  type: string,
  message: string,
  options?: Record<string, any>
) {
  if (type === "error") {
    toast.error(message, {
      ...options,
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  if (type === "success") {
    toast.success(message, {
      ...options,
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-success",
      bodyClassName: "bodytoast",
    });
  }
}
