import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 16px;
  &:last-child {
    margin-right: 0;
  }
  label {
    font-family: "Gilroy-SemiBold";
    font-size: 16px;
    color: #000;
    margin-bottom: 8px;
  }
  input {
    width: 100%;
    height: 48px;
    border-radius: 6px;
    border: 1px #bebebe solid;
    padding: 16px;
    font-size: 14px;
    font-family: "Gilroy-Regular";
    color: #606060;
  }
  span {
    font-size: 14px;
    font-family: "Gilroy-SemiBold";
    color: #e74c3c;
    margin: 4px 0;
  }
`;
