import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 50%;
  margin-right: 40px;

  @media screen and (max-width: 1180px) {
    padding: 0 32px;
  }

  @media screen and (max-width: 800px) {
    max-width: 100%;
    margin-right: 0;

    &:last-child {
      margin-top: 24px;
    }
  }

  &:last-child {
    margin-right: 0;
  }

  p {
    font-size: 16;
    line-height: 22px;
    color: #333;
    margin-bottom: 24px;
  }
`;

export const Title = styled.h1`
  width: 100%;
  max-width: 50%;
  font-family: "Gilroy-SemiBold";
  font-size: 62px;
  line-height: 62px;
  color: #000;
  margin-bottom: 32px;

  @media screen and (max-width: 1180px) {
    padding: 0 32px;
  }
  @media screen and (max-width: 800px) {
    max-width: 100%;
  }
  @media screen and (max-width: 500px) {
    font-size: 32px;
    line-height: 32px;
  }
`;
