import styled from "styled-components";
import { motion } from "framer-motion";
import symbol from "assets/images/symbol-corp.png";
import { Whatsapp } from "@styled-icons/boxicons-logos";
import plusLeft from "assets/images/client-plus-left.png";
import plusRight from "assets/images/client-plus-right.png";

export const Container = styled.section`
  width: 100%;
  max-width: 794px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 96px 32px;
  position: relative;
  overflow: hidden;
`;

export const Title = styled.h1`
  font-size: 62px;
  line-height: 62px;
  color: #000;
  margin-bottom: 16px;
  text-align: center;

  @media screen and (max-width: 414px) {
    font-size: 40px;
    line-height: 40px;
  }
`;

export const Text = styled.p`
  width: 100%;
  max-width: 470px;
  font-size: 16px;
  line-height: 22px;
  color: #000;
  text-align: center;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin: 96px 0 140px 0;

  @media screen and (max-width: 420px) {
    justify-content: center;
    flex-direction: column;

    img {
      margin-right: 0;
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 16px;
      }
    }
  }

  img {
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const BlueBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #063b5d;
  padding: 96px 32px 64px 32px;
  position: relative;
  width: 100%;

  p {
    font-size: 16px;
    color: #fff;
    margin-bottom: 32px;
    text-align: center;
    width: 100%;
    max-width: 470px;
  }
`;

export const SymbolCorp = styled(motion.img).attrs({ src: symbol })`
  width: 100%;
  max-width: max-content;
  top: -58px;
  position: absolute;
  z-index: 1;
`;

export const TitleBox = styled.h3`
  font-size: 38px;
  line-height: 38px;
  color: #fff;
  margin-bottom: 24px;
  text-align: center;

  @media screen and (max-width: 414px) {
    font-size: 24px;
    line-height: 24px;
  }
`;

export const WhatsAppIcon = styled(Whatsapp).attrs({ size: 20 })`
  margin-right: 8px;
`;

export const PlusLeft = styled.img.attrs({ src: plusLeft })`
  position: absolute;
  left: -40%;
  bottom: 32%;
`;

export const PlusRight = styled.img.attrs({ src: plusRight })`
  position: absolute;
  right: -40%;
  bottom: 16%;
`;

export const FormContainer = styled.div`
  form {
    label {
      color: #ffffff;
    }
  }
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
`;
