import React from "react";

import * as S from "./styles";

const Termografia: React.FC = () => {
  return (
    <>
      <S.Title>Termografia</S.Title>
      <S.Container>
        <S.Content>
          <p>
            Já imaginou que por meio da captura de imagens será possível
            levantar dados além da gravação? Isso mesmo! O mercado só fala de
            inteligência artificial, de bigdata, de analíticos de reconhecimento
            facial e de leitura de placas veiculares. Há, também, uma solução
            importante que poderá auxiliar empresas e, principalmente as
            autoridades: trata-se da termografia. O que seria essa solução? É a
            aferição da temperatura corporal, que usa raios infravermelhos para
            indicar a presença de febre.
          </p>

          <p>
            Onde as aferições de temperatura corporal, através das imagens,
            podem ser aplicadas:
          </p>

          <p>
            • Hospitais e Postos de Saúde (Entrada, Saguões, Corredores);
            <br />
            • Transporte (Estações, Terminais, Aeroportos);
            <br />
            • Prédios em Geral (Comerciais, Públicos, Condomínios, Escolas).
            <br />
          </p>
        </S.Content>
        <S.Content>
          <p>
            Os equipamentos podem ser instalados de forma definitiva ou
            temporária, e usados para triagem de pacientes ou controle de
            acesso. Com margem de assertividade de 0,3º a 0,5º e distância de
            medição máxima de 5 metros do ponto de instalação.
          </p>

          <p>
            A Guiro Corp acredita que a aplicação das câmeras termográficas é
            uma medida pequena, mas vital para combater a propagação de
            epidemias, inclusive da atual que enfrentamos.
          </p>

          <p>
            Se você deseja mais informações sobre o uso de termografia para o
            controle epidemiológico em pontos estratégicos, fale com nosso time.
            Vamos usar nossas tecnologias para a contenção dessa e de outras
            epidemias.
          </p>
        </S.Content>
      </S.Container>
    </>
  );
};

export default Termografia;
