/* eslint-disable object-curly-newline */
/* eslint-disable no-undef */
import React, { useEffect, useRef, useState } from "react";
import { useField } from "@unform/core";
import ReactInputMask, {
  Props as MaskedInputProps,
  InputState,
} from "react-input-mask";

import * as S from "./styles";

interface Props {
  name: string;
  type: string;
  label?: string;
}

interface MaskConfig {
  mask: string;
  formatChars: Record<string, string>;
}

const maskConfigs: Record<string, MaskConfig> = {
  telefone: {
    mask: "(99) 9999-9999?",
    formatChars: { "9": "[0-9]", "?": "[0-9]" },
  },
  celular: {
    mask: "(99) 99999-9999",
    formatChars: { "9": "[0-9]", "?": "[0-9]" },
  },
};

export const MaskedInput: React.FC<Props> = ({
  name,
  type,
  label,
  ...rest
}) => {
  const inputRef = useRef(null);
  const [currentMaskConfig, setCurrentMaskConfig] = useState<string>(type);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  function telefoneTransformer(newState: InputState) {
    const length = newState.value.length;
    if (length > 14) {
      setCurrentMaskConfig("celular");
    } else {
      setCurrentMaskConfig("telefone");
    }
  }

  function handleBeforeMaskedValueChange(
    newState: InputState,
    oldState: InputState,
    userInput: string
  ): InputState {
    switch (type) {
      case "telefone":
        telefoneTransformer(newState);
        break;
    }
    return newState;
  }

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
      setValue(ref: any, value: string) {
        ref.setInputValue(value);
        console.log("value", value);
      },
      clearValue(ref: any) {
        ref.setInputValue("");
      },
    });
  }, [fieldName, registerField]);

  return (
    <S.Container>
      {label && <label htmlFor={fieldName}>{label}</label>}
      <ReactInputMask
        {...rest}
        ref={inputRef}
        name={fieldName}
        defaultValue={defaultValue}
        mask={maskConfigs[currentMaskConfig].mask}
        formatChars={maskConfigs[currentMaskConfig].formatChars}
        beforeMaskedValueChange={handleBeforeMaskedValueChange}
        maskChar=""
      />
      {error && <span className="error">{error}</span>}
    </S.Container>
  );
};
