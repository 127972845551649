import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { toast } from "react-toastify";

import Routes from "routes";
import GlobalStyle from "./styles/global";

import ReactGA from "react-ga";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-toastify/dist/ReactToastify.css";

toast.configure();

const App: React.FC = () => {
  useEffect(() => {
    ReactGA.initialize("UA-717432-102");
  }, []);

  return (
    <BrowserRouter basename="/">
      <GlobalStyle />
      <Routes />
    </BrowserRouter>
  );
};

export default App;
