import styled from "styled-components";
import { motion } from "framer-motion";
import bg from "assets/images/slide1-bg.png";
import bgMobile from "assets/images/slide1-bg-mobile.png";
import iphone from "assets/images/iphone.png";

export const Container = styled.section`
  width: 100%;
  max-width: 1370px;
  height: 630px;
  display: flex;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
  position: relative;

  &.left {
    background: rgb(11, 43, 64);
    background: radial-gradient(
      circle,
      rgba(11, 43, 64, 1) 20%,
      rgba(14, 32, 42, 1) 100%
    );

    @media screen and (max-width: 1200px) {
      width: 60%;
    }

    @media screen and (max-width: 930px) {
      display: none;
    }
  }

  &.right {
    background: #dde1e4 url(${bg}) no-repeat center center;
    width: 100%;

    @media screen and (max-width: 930px) {
      background: #dde1e4 url(${bgMobile}) no-repeat;
      background-position-x: center;
      background-position-y: bottom;
      background-size: contain;
    }
  }
`;

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;

export const MobileView = styled.div`
  display: none;
  width: 100%;
  padding: 48px 32px;
  background: rgb(11, 43, 64);
  background: radial-gradient(
    circle,
    rgba(11, 43, 64, 1) 20%,
    rgba(14, 32, 42, 1) 100%
  );

  h1 {
    font-size: 40px;
    line-height: 40px;
    color: #fff;
    margin-bottom: 16px;
  }

  h2 {
    font-size: 16px;
    line-height: 22px;
    font-family: "Gilroy-Regular";
    color: #1da6c7;
  }

  @media screen and (max-width: 930px) {
    display: block;
  }
`;

export const WrapperPhone = styled.div`
  width: 182px;
  height: 390px;
  position: absolute;
  left: 16%;

  @media screen and (max-width: 930px) {
    display: none;
  }
`;

export const Iphone = styled(motion.img).attrs({ src: iphone })`
  position: absolute;
`;

export const CircleBig = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 128px;
  left: 20px;
  background-color: rgba(229, 156, 40, 0.25);
  width: 76px;
  height: 76px;
  border-radius: 50%;
  z-index: 1;
`;

export const Circle = styled(motion.div)`
  position: absolute;
  background-color: rgba(229, 156, 40, 0.71);
  width: 42px;
  height: 42px;
  border-radius: 50%;
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 410px;
`;

export const Subtitle = styled.h5`
  font-size: 20px;
  text-transform: uppercase;
  color: #1da6c7;
  margin-bottom: 32px;
`;

export const Title = styled.h1`
  font-size: 65px;
  color: #fff;
  margin-bottom: 24px;
`;

export const Disclaimer = styled.h2`
  font-size: 22px;
  font-family: "Gilroy-Regular";
  color: #1da6c7;
  margin-bottom: 32px;
`;

export const Text = styled.p`
  font-size: 16px;
  line-height: 22px;
  color: #fff;
`;
