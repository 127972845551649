import React from "react";

import * as S from "./styles";

const Termologia: React.FC = () => {
  return (
    <S.Container>
      <S.Content className="left">
        <S.Wrapper>
          <S.Subtitle>Covid-19</S.Subtitle>
          <S.Title>Termografia Digital</S.Title>
          <S.Disclaimer>
            Checagem de temperatura corporal por meio de imagens de câmera
          </S.Disclaimer>
          <S.Text>
            A instalação de câmeras termográficas auxiliam na triagem preliminar
            de possíveis infectados, sem contato físico e com uma velocidade de
            leitura de alguns segundos.
          </S.Text>
        </S.Wrapper>
      </S.Content>
      <S.WrapperContent>
        <S.Content className="right">
          <S.WrapperPhone>
            <S.CircleBig
              animate={{ scale: [0.5, 1] }}
              transition={{
                ease: "easeInOut",
                duration: 1.5,
                repeat: Infinity,
                repeatType: "reverse",
              }}
            >
              <S.Circle
                animate={{ scale: [1, 1.2] }}
                transition={{
                  ease: "easeInOut",
                  duration: 1,
                  repeat: Infinity,
                  repeatType: "reverse",
                }}
              />
            </S.CircleBig>
            <S.Iphone />
          </S.WrapperPhone>
        </S.Content>
        <S.MobileView>
          <h1>Termografia Digital</h1>
          <h2>
            Checagem de temperatura corporal por meio de imagens de câmera
          </h2>
        </S.MobileView>
      </S.WrapperContent>
    </S.Container>
  );
};

export default Termologia;
