import React, { useEffect, useState, useCallback } from "react";
import ReactGA from "react-ga";
import { scroller } from "react-scroll";

import * as S from "./styles";
import { Button } from "styles/components";

const Header: React.FC = () => {
  const [transform, setTransform] = useState(false);

  const handleScroll = useCallback(() => {
    if (window.scrollY > 30) return setTransform(true);
    return setTransform(false);
  }, []);

  const handleMenu = useCallback((element: string) => {
    ReactGA.event({
      category: "Menu",
      action: element,
      label: "Menu",
    });
    scroller.scrollTo(element, {
      duration: 400,
      delay: 0,
      offset: -90,
    });
  }, []);

  const handleContact = useCallback(() => {
    ReactGA.event({
      category: "Contato",
      action: "Fale com a Guiro",
      label: "Menu",
    });
    window.open(`https://wa.me/5521983860006`, "_blank", "noopener");
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);
  return (
    <S.Container transform={transform}>
      <S.Content>
        <S.Logo />
        <S.Menu>
          <S.Option
            whileHover={{ color: "#0075b0" }}
            onClick={() => handleMenu("About")}
          >
            A Guiro Corp
          </S.Option>
          <S.Option
            whileHover={{ color: "#0075b0" }}
            onClick={() => handleMenu("Security")}
          >
            Soluções em Segurança
          </S.Option>
          <S.Option
            whileHover={{ color: "#0075b0" }}
            onClick={() => handleMenu("Client")}
          >
            Nossos Clientes
          </S.Option>
          <S.Option
            whileHover={{ color: "#0075b0" }}
            onClick={() => handleMenu("Contact")}
          >
            Contato
          </S.Option>
          <Button whileHover={{ scale: 1.1 }} onClick={handleContact}>
            <S.WhatsAppIcon />
            Fale com a Guiro
          </Button>
        </S.Menu>
      </S.Content>
    </S.Container>
  );
};

export default Header;
