import styled from "styled-components";
import { motion } from "framer-motion";
import plus from "assets/images/about-plus.png";
import symbol from "assets/images/symbol-corp.png";

export const Container = styled.section`
  display: flex;
  width: 100%;
  max-width: 1370px;
  padding: 160px 32px;
  overflow: hidden;

  @media screen and (max-width: 1020px) {
    flex-direction: column;
  }
`;

export const Content = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 72px;

  p {
    font-size: 16px;
    line-height: 22px;
    color: #333;
  }

  @media screen and (max-width: 1020px) {
    width: 100%;

    &:last-child {
      margin-top: 32px;
    }
  }

  @media screen and (max-width: 500px) {
    padding: 0;
  }
`;

export const PlusLeft = styled(motion.img).attrs({ src: plus })`
  position: absolute;
  top: -30px;
  left: 25%;
  z-index: 0;
`;

export const PlusRight = styled(motion.img).attrs({ src: plus })`
  position: absolute;
  bottom: -80px;
  right: 24px;
`;

export const SymbolCorp = styled(motion.img).attrs({ src: symbol })`
  margin-bottom: 24px;
  width: 100%;
  height: 100%;
  max-width: max-content;
  max-height: max-content;
  position: relative;
  z-index: 1;
`;

export const Wrapper = styled.div`
  margin-bottom: 48px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Title = styled.h2`
  font-size: 58px;
  font-family: "Gilroy-SemiBold";
  color: #0075b0;
  margin-bottom: 32px;

  @media screen and (max-width: 414px) {
    font-size: 48px;
  }
`;

export const Subtitle = styled.h3`
  font-size: 24px;
  font-family: "Gilroy-SemiBold";
  color: #000;
  margin-bottom: 16px;
`;
