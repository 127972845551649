import styled from "styled-components";
import { motion } from "framer-motion";
import mina from "assets/images/mina.png";
import minaMobile from "assets/images/mina-mobile.png";
import iphone from "assets/images/iphone.png";

export const Container = styled.section`
  display: flex;
  width: 100%;
  max-width: 1920px;
  height: 670px;
  background-color: #e7eaec;

  @media screen and (max-width: 920px) {
    flex-direction: column-reverse;
    height: 900px;
  }
  @media screen and (max-width: 414px) {
    height: auto;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  @media screen and (max-width: 920px) {
    max-width: 100%;
  }

  p {
    font-size: 16px;
    line-height: 22px;
    color: #000;

    @media screen and (max-width: 500px) {
      font-size: 14px;
      line-height: 18px;
      text-align: center;
    }
  }

  &.left {
    align-items: center;
    justify-content: center;
    padding: 48px;

    @media screen and (max-width: 414px) {
      padding: 32px 32px 64px 32px;
    }
  }

  &.right {
    align-items: flex-start;
    justify-content: center;
    padding-right: 32px;
    background: url(${mina}) no-repeat bottom center;

    @media screen and (max-width: 920px) {
      background: url(${minaMobile}) no-repeat center center;
      padding-right: 0;
      background-size: contain;
    }
    @media screen and (max-width: 414px) {
      height: 350px;
      background-size: cover;
    }
  }
`;

export const Title = styled.h1`
  font-size: 46px;
  line-height: 46px;
  color: #000;
  margin-bottom: 16px;

  @media screen and (max-width: 500px) {
    font-size: 32px;
    line-height: 32px;
    text-align: center;
  }
`;

export const Subtitle = styled.h1`
  font-size: 26px;
  font-family: "Gilroy-Regular";
  line-height: 26px;
  color: #1da6c7;
  margin-bottom: 40px;

  @media screen and (max-width: 500px) {
    font-size: 18px;
    line-height: 18px;
    text-align: center;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 510px;
`;

export const WrapperPhone = styled.div`
  width: 182px;
  height: 390px;
  position: absolute;
  left: 10%;

  @media screen and (max-width: 920px) {
    display: none;
  }
`;

export const Iphone = styled(motion.img).attrs({ src: iphone })`
  position: absolute;
`;

export const CircleBig = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 128px;
  left: 20px;
  background-color: rgba(229, 156, 40, 0.25);
  width: 76px;
  height: 76px;
  border-radius: 50%;
  z-index: 1;
`;

export const Circle = styled(motion.div)`
  position: absolute;
  background-color: rgba(229, 156, 40, 0.71);
  width: 42px;
  height: 42px;
  border-radius: 50%;
`;
