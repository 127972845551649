import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import logo from "assets/images/logo.png";
import { Whatsapp } from "@styled-icons/boxicons-logos";

export const Logo = styled.img.attrs({
  src: logo,
})`
  width: 100%;
  max-width: max-content;
`;

export const Container = styled.header<{ transform: boolean }>`
  width: 100%;
  max-width: 1920px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 32px;
  position: sticky;
  top: 0;
  z-index: 99;

  ${({ transform }) =>
    transform &&
    css`
      background: #fff;
      padding: 8px 32px;
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);

      @media screen and (max-width: 1023px) {
        position: relative;
        background: transparent;
        box-shadow: none;
        ${Logo} {
          width: 100%;
        }
      }
    `}

  ${({ transform }) =>
    transform &&
    css`
      ${Logo} {
        width: 280px;
      }

      @media screen and (max-width: 1023px) {
        ${Logo} {
          width: 100%;
        }
      }
    `}
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1370px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 600px) {
    justify-content: center;
  }
`;

export const Menu = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 600px) {
    display: none;
  }
`;

export const Option = styled(motion.button).attrs({ type: "button" })`
  font-size: 16px;
  color: #061c2b;
  margin-right: 40px;

  &:last-child {
    margin-right: 0;
  }

  @media screen and (max-width: 1210px) {
    display: none;
  }
`;

export const WhatsAppIcon = styled(Whatsapp).attrs({ size: 20 })`
  margin-right: 8px;
`;
