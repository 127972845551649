import styled from "styled-components";
import logo from "assets/images/logo.png";
import { Facebook, Instagram, Linkedin } from "@styled-icons/boxicons-logos/";

export const Container = styled.footer`
  width: 100%;
  max-width: 1920px;
  background-color: #ebebeb;
  padding: 64px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1340px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 32px;
  border-bottom: 1px #adbfc7 solid;
  margin-bottom: 56px;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Logo = styled.img.attrs({ src: logo })`
  width: 100%;
  max-width: max-content;
  height: max-content;
`;

export const About = styled.p`
  font-size: 14px;
  line-height: 22px;
  color: #333;
  flex: 1;
  max-width: 400px;

  @media screen and (max-width: 1024px) {
    text-align: center;
    margin: 24px 0 16px 0;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 300px;
  padding: 0 8px;

  @media screen and (max-width: 1024px) {
    text-align: center;
    margin: 16px 0;
  }

  &.social {
    max-width: 140px;
  }

  p {
    font-size: 14px;
    line-height: 22px;
  }
`;

export const Title = styled.h6`
  font-size: 24px;
  margin-bottom: 16px;
  color: #000;

  @media screen and (max-width: 1024px) {
    text-align: center;
  }
`;

export const Social = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */

  @media only screen and (max-width: 640px) {
    justify-content: center;
  }
  a {
    text-decoration: none;
    color: #000;
  }
`;

export const IconFacebook = styled(Facebook).attrs({ size: 24 })``;

export const IconInstagram = styled(Instagram).attrs({ size: 24 })`
  margin-right: 16px;
`;

export const IconLinkedin = styled(Linkedin).attrs({ size: 24 })``;

export const Dev = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1340px;

  span,
  a {
    font-size: 14px;
  }

  a {
    text-decoration: underline;
    color: #333;
  }

  @media screen and (max-width: 660px) {
    flex-direction: column;

    span {
      margin-bottom: 16px;
    }
  }
`;
