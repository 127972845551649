import React from "react";

import * as S from "./styles";

const Camera: React.FC = () => {
  return (
    <S.Container>
      <S.Content className="left"></S.Content>
      <S.Content className="right">
        <S.Wrapper>
          <S.Title>Uma nova geração de câmeras de vigilância</S.Title>
          <S.Subtitle>
            Experimente o avanço em resolução e qualidade de filmagem.
          </S.Subtitle>

          <p>
            • Armazenamento reduzido em boa qualidade
            <br />
            • Imagens claras e nítidas mesmo em ambientes com pouca luz
            <br />
            • Equalização de sombra nas imagens <br />
            • Estabilização eletrônica da imagem, mesmo quando ocorrer um
            balanço do aparelho
            <br />
            • Gravação em alta resolução até 4K Ultra HD
            <br />
            • Amplo alcance dinâmico
            <br />
            • Emissão de luz na medida certa
            <br />
            • Mapa de calor
            <br />• Imagens coloridas no escuro
          </p>
        </S.Wrapper>
      </S.Content>
    </S.Container>
  );
};

export default Camera;
