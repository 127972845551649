import React from "react";

import * as S from "./styles";
import illustration from "assets/images/service-monitor.png";

const Monitor: React.FC = () => {
  return (
    <S.Container>
      <S.Content className="left">
        <S.Title>Monitoramento e Vigilância</S.Title>
        <p>
          Em parceria com fabricantes líderes de mercado, a Guiro Corp utiliza
          em seus projetos o que há de mais tecnológico e moderno na área de
          câmera de segurança. Tudo isso para que você fique tranquilo. Afinal,
          sabemos o quanto não é fácil empreender, por isso zelamos pelo seu
          patrimônio. Faça nos um contato e conheça algumas das principais
          tecnologias e funcionalidades dos nossos produtos.
        </p>
        <p>
          Disponibilizamos, inclusive para nossos clientes, a opção de
          acompanhar as imagens de sua empresa pelo seu celular, tablet ou outro
          dispositivo que esteja conectado a internet. Isso mesmo! Você pode
          estar fora do país, em casa ou em qualquer outro lugar, que você
          conseguirá visualizar em tempo real tudo que está acontecendo em seu
          empreendimento.
        </p>
      </S.Content>
      <S.Content className="right">
        <img src={illustration} alt="Monitoramento e Vigilância" />
      </S.Content>
    </S.Container>
  );
};

export default Monitor;
